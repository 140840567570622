import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../auth/AuthProvider';
import { BorderFreeRounded } from '../pages/Report/styles/Wrappers';
import { sendSlackBotReportMessage, updateSlackBotMessage } from '../requests/reports';
import constants from '../shared/constants';
import { Button } from '../shared/styles/Buttons';
import { MiniSpinner, Spinner } from '../shared/styles/Spinner';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;
const Shader = styled(Wrapper)`
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
`;

const FlexSpan = styled.span`
    display: flex;
`;

const FilesExistListElement = styled.span`
    margin-right: 8px;
`;

const CenteredErrorMessage = styled(BorderFreeRounded)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    padding: 2vw;
    text-align: center;
    align-items: center;
`;

const SlackBotFeedbackButton: FC<{ hasImages?: boolean; hasAIS?: boolean }> = ({ hasImages, hasAIS }) => {
    const { token } = useAuth();
    const [clicked, setClicked] = useState<boolean>(false);
    const imageParam: boolean | null = typeof hasImages !== 'undefined' ? hasImages : null;
    const AISParam: boolean | null = typeof hasAIS !== 'undefined' ? hasAIS : null;

    useMemo(() => {
        sendSlackBotReportMessage(token, window.location.href, imageParam, AISParam);
    }, [token, imageParam, AISParam]);
    const handleClick = () => {
        if (!clicked) {
            updateSlackBotMessage(token, window.location.href, imageParam, AISParam).then((res) => {
                setClicked(res);
            });
        }
    };
    return (
        <Button
            background={clicked ? constants.colors.pallet.secondary : constants.colors.pallet.blue}
            onClick={handleClick}
        >
            {clicked ? 'VAKE notified' : 'Notify VAKE'}
        </Button>
    );
};

const HandleMissingPFData: FC<{
    createdAt?: number | null;
    hasImages: boolean | undefined;
    hasAIS: boolean | undefined;
    showMessage: boolean;
    setShowMessage: (value: boolean) => void;
}> = ({ createdAt, hasImages, hasAIS, showMessage, setShowMessage }) => {
    const minutesThreshold = 10;
    const date = new Date();
    const timeUTC = date.getTime() + date.getTimezoneOffset() * 60000;
    const timeDiffMinutes = createdAt ? (timeUTC - createdAt) / (1000 * 60) : null;
    if (timeDiffMinutes && timeDiffMinutes < minutesThreshold) {
        return (
            <h3>
                We are creating your report. <br />
                It should be ready in less than {Math.floor(minutesThreshold - timeDiffMinutes - 1)} minute
                {minutesThreshold - timeDiffMinutes - 1 === 1 ? '' : 's'}
            </h3>
        );
    }
    // Handle undefined situation also
    if (hasImages || hasAIS || typeof hasImages === 'undefined' || typeof hasAIS === 'undefined') {
        return (
            <>
                <h3>
                    Your report is missing {hasAIS ? 'images' : 'AIS'}
                    <br />
                </h3>
                <img style={{ height: '40px' }} src="/images/icons/satellite.svg" alt="" />
                <ul>
                    This is usually a sign of missing data for the time period or something wrong with the processing on
                    our end.
                </ul>
                <span>
                    <Button
                        background={showMessage ? '#31D158' : undefined}
                        style={{ marginRight: '8px' }}
                        onClick={() => setShowMessage(true)}
                    >
                        Show Report
                    </Button>
                    <SlackBotFeedbackButton hasImages={hasImages} hasAIS={hasAIS} />
                </span>
            </>
        );
    }
    if (!(hasImages && hasAIS))
        return (
            <>
                <h3>
                    Your report was not generated correcly <br />
                </h3>
                <img style={{ height: '40px' }} src="/images/icons/satellite.svg" alt="" />
                <ul>
                    This is typically because AIS data does not exist for this time periode, <br /> or something went
                    wrong on our end.
                </ul>
                <SlackBotFeedbackButton hasImages={hasImages} hasAIS={hasAIS} />
            </>
        );
    return null;
};

export const PathfinderErrorHandler: FC<{
    hasAIS?: boolean;
    hasImages?: boolean;
    createdAt?: number | null;
    isLoading?: boolean;
}> = ({ hasAIS, hasImages, createdAt, isLoading }) => {
    // Handle if user want to see imperfect report
    const [showMessage, setShowMessage] = useState<boolean>(false);
    if (hasImages === true && hasAIS === true && !isLoading) return null;
    if (isLoading || !showMessage)
        return (
            <Shader>
                <CenteredErrorMessage style={{ zIndex: 99 }}>
                    <h3 style={{ marginBottom: '1vw' }}>Pathfinder Report status: </h3>
                    <>
                        <FlexSpan>
                            <FilesExistListElement>Gathering AIS track</FilesExistListElement>
                            <CheckOrCrossSymbol check={hasAIS} />
                        </FlexSpan>
                        <FlexSpan>
                            <FilesExistListElement>Correlating satelitte images with AIS track</FilesExistListElement>
                            <CheckOrCrossSymbol check={hasImages} />
                        </FlexSpan>
                    </>
                    <div style={{ height: '2vw' }} />
                    {hasAIS === false || hasImages === false ? (
                        <HandleMissingPFData
                            createdAt={createdAt}
                            hasImages={hasImages}
                            hasAIS={hasAIS}
                            showMessage={showMessage}
                            setShowMessage={setShowMessage}
                        />
                    ) : null}
                    {isLoading ? (
                        <>
                            <Spinner />
                            <h4>Loading report...</h4>
                        </>
                    ) : null}
                </CenteredErrorMessage>
            </Shader>
        );
    return null;
};

export const ClearviewErrorHandler: FC = () => {
    return (
        <Shader>
            <CenteredErrorMessage>
                Your user does not have access to the VAKE tool for locating dark vessels.
                <div style={{ height: '10%', maxWidth: '40px', margin: '2%' }}>
                    <img width={'100%'} src="/images/icons/cross_white.svg" alt="no source" />
                </div>
                Contact VAKE for a trial.
            </CenteredErrorMessage>
        </Shader>
    );
};

export const CheckOrCrossSymbol: FC<{ check?: boolean }> = ({ check }) => {
    if (typeof check === 'undefined') {
        return <MiniSpinner size={20} />;
    } else if (check) {
        return <img src="/images/icons/simpleSymbols/check.svg" alt="check" />;
    } else {
        return <img src="/images/icons/simpleSymbols/x.svg" alt="check" />;
    }
};
